
.logo-wrapper {
  background-color: white;
  padding-bottom: 200px;
}
.logo-wrapper a {
  padding-bottom: 200px !important;
}
.logo-wrapper a img {
  padding-top: 2% !important;
  padding-bottom: 10% !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.side-nav.wide .collapsible a {
  padding-left: 0px !important;
}
.side-nav a {
  padding-left: 0px !important;
}
.menu_izq_letras {
  font-size: x-large !important;
}

.side-nav .collapsible a {
  font-size: 18px !important;
  color: black !important;
}
.label_idioma {
  color: black !important;
}
.nabbar_text_email a{
  color: black !important;
}

.section_enfoque, .section_timeline {
  background-color: #f5f5f5;
}

.section_testimonios{
  background-color: #f5f5f5;
  padding-bottom: 1px;
}